<template>
  <div class="row">
    <slot v-for="slotName in sortedSlots" :name="slotName"></slot>
  </div>
</template>
<script>
export default {
  name: "OrderedComponents",
  computed: {
    sortedSlots() {
      return Object.keys(this.$slots).sort((a, b) => a - b);
    },
  },
};
</script>